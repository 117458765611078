<template>
  <div id="app">
    <div class="layout_login">
      <div class="app_header">
        <div class="header w">
          <div class="nav_logo">
            <NuxtLink to="/">
              <img
                :src="nav && nav.websiteLogo"
                alt=""
              >
            </NuxtLink>
          </div>
          <div
            class="handle_bar"
            @click="goBack"
          >
            <span class="handle_bar_item">返回首页 <i class="iconfont icon-icon_Go" /></span>
          </div>
        </div>
      </div>
      <div class="app_main w">
        <slot />
      </div>
      <div class="footer w">
        <div class="copyright">
          <p>Copyright © {{ componentInfo && componentInfo.copyright }}</p>
          <!-- <p>粤ICP备16009964号<span>|</span><img src="/assets/images/login/copyright.png" alt="" />粤公网安备44010602005928号</p> -->
        </div>
      </div>
      <back-top />
    </div>
  </div>
</template>

<script setup>
import cookie from '@/utils/cookies'
import { useUserStore } from '../stores/user'
import { getHearder, getFooter } from '@/api/layouts'
const { data: nav } = await useAsyncData('loginHeader', () => {
  return getHearder()
}, {})
const { data: componentInfo } = await useAsyncData('loginFooter', () => {
  return getFooter()
}, {})

const { getShopInfo } = useUserStore()
getShopInfo()

const router = useRouter()
const goBack = () => {
  router.push({ path: '/' })
}
const route = useRoute()
const cookieData = cookie.getShop()
useHead({
  link: [{ rel: 'icon', type: 'image/x-icon', href: cookieData.shopIcon }]
})
</script>

<style lang="scss" scoped>
.nav_logo {
  height: 100%;
  width: 170px;
  display: flex;
  a{
    height: 100%;
  display: flex;
  }
  img {
    height: 60px;
    display: flex;
    margin: auto 0;
    width: auto;
  }
}
.layout_login {
  min-width: 1200px;
  min-height: 100vh;
  background: rgba(36, 91, 246, 1) url("../assets/images/login/bj.jpg")
    no-repeat;
  background-size: cover;
}
.app_header {
  background: #fff;
}
.header {
  height: 66px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .handle_bar_item {
    cursor: pointer;
  }
}
.footer {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1000;
  padding-bottom: 30px;
  font-size: 12px;
  .copyright {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #fff;
    p {
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 14px;
        height: 14px;
        margin: 0 5px;
      }
      span,
      img {
        margin-left: 5px;
      }
    }
  }
}
</style>
